<template>
  <div>
    <h1 class="mb-3">Reports</h1>
    <ul class="list-group">
      <li class="list-group-item list-group-item-light">
        <div class="d-flex justify-content-between align-items-center" style="min-height: 38px;">
          <h2 class="h5 m-0">Orders</h2>
          <div>
            <progress v-if="orders.busy"></progress>
            <form class="form-inline" @submit.prevent="downloadOrdersReport" v-else>
              <label for="orders-from">From:</label>
              <input type="date" class="form-control ml-2 mr-4" id="orders-from" v-model="orders.from" />
              <label for="orders-to">To:</label>
              <input type="date" class="form-control ml-2 mr-4" id="orders-to" v-model="orders.to" />
              <label for="orders-selector-field">By:</label>
              <select class="custom-select ml-2 mr-4" id="orders-selector-field" v-model="orders.selectorField">
                <option value="deliveryDate">Delivery Date</option>
                <option value="createdAt">Order Date</option>
              </select>
              <button class="btn btn-primary" type="submit">
                Generate
              </button>
            </form>
          </div>
        </div>
      </li>
      <li class="list-group-item list-group-item-light">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="h5 mt-2 mb-2">Accounting Overview</h2>
          <progress v-if="accountingOverview.busy"></progress>
          <button class="btn btn-primary" @click="generateAccountingOverview" v-else>
            Generate
          </button>
        </div>
      </li>
      <li class="list-group-item list-group-item-light">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="h5 m-0">Warehouse Activities</h2>
          <div>
            <form class="form-inline" @submit.prevent="openWarehouseActivities">
              <label for="orders-to">Day:</label>
              <input type="date" class="form-control ml-2 mr-4" id="orders-to" v-model="warehouseActivitiesDay" />
              <button type="submit" class="btn btn-primary">
                Show
              </button>
            </form>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import GenerateOrdersReport from '../helpers/GenerateOrdersReport'

export default {
  data() {
    return {
      orders: {
        from: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
        selectorField: 'deliveryDate',
        busy: false
      },
      warehouseActivitiesDay: moment().format('YYYY-MM-DD'),
      accountingOverview: {
        busy: false
      }
    }
  },
  methods: {
    async downloadOrdersReport() {
      this.orders.busy = true
      const from = moment(this.orders.from)
      const to = moment(this.orders.to).endOf('day')
      const orders = await db
        .collection('orders')
        .where(this.orders.selectorField, '>=', from.toDate())
        .where(this.orders.selectorField, '<=', to.toDate())
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      const { organizations, products } = this.$store.state
      const equipmentInvoices = await db
        .collection('invoices')
        .where('date.invoice', '>=', from.toDate())
        .where('date.invoice', '<=', to.toDate())
        .where('type', '==', 'equipmentInvoice')
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      const catalog = await db
        .doc('public/catalog')
        .get()
        .then(doc => doc.data())
      await GenerateOrdersReport(organizations, orders, products, catalog, equipmentInvoices, from, to)
      this.orders.busy = false
    },
    openWarehouseActivities() {
      this.$router.push({ name: 'warehouseactivities', params: { day: this.warehouseActivitiesDay } })
    },
    async generateAccountingOverview() {
      this.accountingOverview.busy = true
      const organizations = this.$store.state.organizations.filter(o => o.demo === false)
      const fourWeeksAgo = moment()
        .subtract(4, 'weeks')
        .toDate()
      const eightWeeksAgo = moment()
        .subtract(8, 'weeks')
        .toDate()
      let results = []
      const promises = []
      organizations.forEach(organizaiton => {
        promises.push(
          new Promise((resolve, reject) => {
            try {
              const { customerId, name, id } = organizaiton
              if (!customerId) return resolve(true)
              const result = {
                customerId,
                name,
                id,
                openAmount: 0,
                openAmountOlderThan4Weeks: 0,
                openAmountOlderThan8Weeks: 0
              }
              db.collection('invoiceCollections')
                .where('organizationId', '==', organizaiton.id)
                .get()
                .then(snapshot => {
                  const invoiceCollections = snapshot.docs
                    .filter(doc => {
                      return doc.data().createdAt.toDate() > new Date('2021-03-01')
                    })
                    .map(doc => {
                      return { ...doc.data(), id: doc.id }
                    })
                  invoiceCollections.forEach(invoiceCollection => {
                    const paidAmount = invoiceCollection.paidAmount || 0
                    const sum = parseFloat(invoiceCollection.sums.gross.total.toFixed(2))
                    let openAmount = parseFloat((sum - paidAmount).toFixed(2))
                    if (openAmount < 0) openAmount = 0
                    result.openAmount = parseFloat((result.openAmount + openAmount).toFixed(2))
                    if (invoiceCollection.createdAt.toDate() < fourWeeksAgo) {
                      result.openAmountOlderThan4Weeks = parseFloat(
                        (result.openAmountOlderThan4Weeks + openAmount).toFixed(2)
                      )
                    }
                    if (invoiceCollection.createdAt.toDate() < eightWeeksAgo) {
                      result.openAmountOlderThan8Weeks = parseFloat(
                        (result.openAmountOlderThan8Weeks + openAmount).toFixed(2)
                      )
                    }
                  })
                  resolve(results.push(result))
                })
            } catch (err) {
              reject(err.message)
            }
          })
        )
      })
      await Promise.all(promises)
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Accounting Overview')
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
      worksheet.columns = [
        { header: 'Customer ID', key: 'customerId', width: 15 },
        { header: 'Name', key: 'name', width: 75 },
        { header: 'Overdue (4 weeks)', key: 'openAmountOlderThan4Weeks', width: 15 },
        { header: 'Overdue (8 weeks)', key: 'openAmountOlderThan8Weeks', width: 15 },
        { header: 'Open (total)', key: 'openAmount', width: 15 }
      ]

      results
        .sort((a, b) => b.openAmountOlderThan4Weeks - a.openAmountOlderThan4Weeks)
        .forEach(result => {
          worksheet.addRow(result)
        })
      ;['openAmountOlderThan4Weeks', 'openAmountOlderThan8Weeks', 'openAmount'].forEach(key => {
        worksheet.getColumn(key).numFmt = '#,##0.00 €'
      })

      workbook.xlsx.writeBuffer().then(function(data) {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `Accounting Overview ${moment().format('YYYY-MM-DD')}.xlsx`)
      })

      this.accountingOverview.busy = false
    }
  }
}
</script>
