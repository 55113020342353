import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'

const GenerateOrdersReport = async (allOrganizations, orders, products, catalog, equipmentInvoices, from, to) => {
  const workbook = new ExcelJS.Workbook()

  const statisticsSheet = workbook.addWorksheet('Statistics')
  const organizationsSheet = workbook.addWorksheet('Organizations')
  const deliveryServiceSheet = workbook.addWorksheet('Delivery Service')
  const kioskSheet = workbook.addWorksheet('Kiosk')
  const salesByCategorySheet = workbook.addWorksheet('Sales by Category')

  let organizations = []
  const statistics = {}

  const statisticRows = ['deliveryService', 'residents', 'homeOrders', 'kiosk', 'ordersTotal', 'equipment', 'total']

  const setupStatistics = () => {
    statisticsSheet.columns = [
      { header: 'Position', key: 'position', width: 25 },
      { header: 'Orders', key: 'orders', width: 25 },
      { header: 'Organizations', key: 'organizations', width: 25 },
      { header: 'Product Revenue', key: 'netProductRevenue', width: 25 },
      { header: 'Delivery Fee Revenue', key: 'netDeliveryFeeRevenue', width: 25 },
      { header: 'Net Revenue', key: 'netRevenue', width: 25 },
      { header: 'Gross Revenue', key: 'grossRevenue', width: 25 },
      { header: 'Orders / Organization', key: 'ordersPerOrganization', width: 25 },
      { header: 'Net Revenue / Organization', key: 'netRevenuePerOrganizaiton', width: 25 },
      { header: 'Net Revenue / Order', key: 'netRevenuePerOrder', width: 25 },
      { header: 'Gross Revenue / Organization', key: 'grossRevenuePerOrganizaiton', width: 25 },
      { header: 'Gross Revenue / Order', key: 'grossRevenuePerOrder', width: 25 }
    ]

    const headerRow = statisticsSheet.getRow(1)
    headerRow.font = {
      color: { argb: 'FFFFFF' },
      bold: true
    }
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FF222222'
      },
      bgColor: {
        argb: 'FFFFFFFF'
      }
    }
    statisticRows.forEach(key => {
      if (key !== 'total') {
        statistics[key] = {
          netRevenue: 0,
          grossRevenue: 0,
          orders: 0,
          organizations: 0
        }
        if (key !== 'equipment') {
          statistics[key].netProductRevenue = 0
          statistics[key].netDeliveryFeeRevenue = 0
        }
      }
    })
  }
  setupStatistics()

  const setupKiosk = () => {
    kioskSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 3 }]

    kioskSheet.columns = [
      { header: 'Collection ID', key: 'invoiceCollectionId', width: 12 },
      { header: 'Organization Name', key: 'organizationName', width: 75 },
      { header: 'Order Date', key: 'orderDate', width: 15 },
      { header: 'Delivery Date', key: 'deliveryDate', width: 15 },
      { header: 'Product Revenue', key: 'totalNetProductRevenue', width: 20 },
      { header: 'Delivery Fee Revenue', key: 'totalNetDeliveryFeeRevenue', width: 20 },
      { header: 'Total Items', key: 'totalItemsCount', width: 20 },
      { header: 'Net Revenue', key: 'totalNetRevenue', width: 20 },
      { header: 'Gross', key: 'totalGrossRevenue', width: 15 }
    ]

    kioskSheet.insertRow(1, { invoiceCollectionId: '' })

    kioskSheet.addRow({ invoiceCollectionId: '' })

    const kioskTitleRow = kioskSheet.getRow(1)
    const kioskHeaderRow = kioskSheet.getRow(2)
    const kioskSumsRow = kioskSheet.getRow(3)
    kioskSumsRow.height = 25
    ;[kioskTitleRow, kioskHeaderRow, kioskSumsRow].forEach(row => {
      row.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }
      ;[
        'totalNetProductRevenue',
        'totalNetDeliveryFeeRevenue',
        'totalItemsCount',
        'totalNetRevenue',
        'totalGrossRevenue'
      ].forEach(cellName => {
        row.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '77A142' }
        }
      })
    })
  }
  setupKiosk()

  const setupEquipmentInvoices = () => {
    equipmentInvoices.forEach(invoice => {
      statistics.equipment.orders++
      statistics.equipment.netRevenue += invoice.sums.net.total
      statistics.equipment.grossRevenue += invoice.sums.gross.total
    })
    const organizationIds = [...new Set(equipmentInvoices.map(invoice => invoice.organizationId))]
    statistics.equipment.organizations = organizationIds.length
  }
  setupEquipmentInvoices()

  const setupDeliveryService = () => {
    deliveryServiceSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 3 }]

    deliveryServiceSheet.columns = [
      { header: 'Collection ID', key: 'invoiceCollectionId', width: 12 },
      { header: 'Organization Name', key: 'organizationName', width: 75 },
      { header: 'Order Date', key: 'orderDate', width: 15 },
      { header: 'Delivery Date', key: 'deliveryDate', width: 15 },
      { header: 'Product Revenue', key: 'residentNetProductRevenue', width: 20 },
      { header: 'Delivery Fee Revenue', key: 'residentNetDeliveryFeeRevenue', width: 20 },
      { header: 'Net Revenue', key: 'residentNetRevenue', width: 20 },
      { header: 'Number of Residents', key: 'numberOfResidents', width: 20 },
      { header: 'Total Items', key: 'residentItemsCount', width: 20 },
      { header: 'Items per Resident', key: 'itemsPerResident', width: 20 },
      { header: 'ø Cart Value', key: 'averageCartValue', width: 20 },
      { header: 'Product Revenue', key: 'homeOrderNetProductRevenue', width: 20 },
      { header: 'Delivery Fee Revenue', key: 'homeOrderNetDeliveryFeeRevenue', width: 20 },
      { header: 'Net Revenue', key: 'homeOrderNetRevenue', width: 20 },
      { header: 'Product Revenue', key: 'totalNetProductRevenue', width: 20 },
      { header: 'Delivery Fee Revenue', key: 'totalNetDeliveryFeeRevenue', width: 20 },
      { header: 'Net Revenue', key: 'totalNetRevenue', width: 20 },
      { header: 'Gross', key: 'totalGrossRevenue', width: 15 }
    ]
    deliveryServiceSheet.insertRow(1, {
      residentNetProductRevenue: 'Resident',
      homeOrderNetProductRevenue: 'Home Order',
      totalNetProductRevenue: 'Total'
    })
    deliveryServiceSheet.addRow({ invoiceCollectionId: '' })

    const categoryRow = deliveryServiceSheet.getRow(1)
    const headerRow = deliveryServiceSheet.getRow(2)
    const sumsRow = deliveryServiceSheet.getRow(3)
    sumsRow.height = 25
    ;[categoryRow, headerRow, sumsRow].forEach(row => {
      row.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }
      ;[
        'residentNetProductRevenue',
        'residentNetDeliveryFeeRevenue',
        'residentNetRevenue',
        'numberOfResidents',
        'residentItemsCount',
        'itemsPerResident',
        'averageCartValue'
      ].forEach(cellName => {
        row.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4290A1' }
        }
      })
      ;['homeOrderNetProductRevenue', 'homeOrderNetDeliveryFeeRevenue', 'homeOrderNetRevenue'].forEach(cellName => {
        row.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8342A1' }
        }
      })
      ;['totalNetProductRevenue', 'totalNetDeliveryFeeRevenue', 'totalNetRevenue', 'totalGrossRevenue'].forEach(
        cellName => {
          row.getCell(cellName).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '77A142' }
          }
        }
      )
    })

    deliveryServiceSheet.mergeCells('E1:K1')
    deliveryServiceSheet.mergeCells('L1:N1')
    deliveryServiceSheet.mergeCells('O1:R1')

    deliveryServiceSheet.getCell('E1').alignment = { horizontal: 'center' }
    deliveryServiceSheet.getCell('K1').alignment = { horizontal: 'center' }
    deliveryServiceSheet.getCell('N1').alignment = { horizontal: 'center' }
  }
  setupDeliveryService()

  const setupOrganizations = () => {
    organizationsSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

    organizationsSheet.columns = [
      { header: '', key: 'icon', width: 5 },
      { header: 'Organization Name', key: 'organizationName', width: 75 },
      { header: 'Order Count', key: 'totalOrderCount', width: 15 },
      { header: 'Net Revenue', key: 'totalNetRevenue', width: 15 },
      { header: 'Notice', key: 'notice', width: 50 }
    ]

    const headerRow = organizationsSheet.getRow(1)
    headerRow.font = {
      color: { argb: 'FFFFFF' },
      bold: true
    }
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FF222222'
      },
      bgColor: {
        argb: 'FFFFFFFF'
      }
    }

    allOrganizations
      .filter(o => o.customerId && o.firstOrderDate.toDate() <= to.toDate())
      .forEach(org => {
        const emptyData = {
          netRevenue: {
            ordersTotal: 0,
            kiosk: 0,
            equipment: 0,
            residents: 0,
            homeOrders: 0
          },
          orderCount: {
            ordersTotal: 0,
            kiosk: 0,
            equipment: 0,
            residents: 0,
            homeOrders: 0,
            deliveryService: 0
          }
        }
        if (org.parentOrganization) {
          const parentOrg = organizations.find(o => o.id === org.parentOrganization.id)
          if (typeof parentOrg === 'undefined') {
            organizations.push({
              ...emptyData,
              id: org.parentOrganization.id,
              name: org.parentOrganization.name,
              type: 'parentOrganization'
            })
          }
        } else {
          organizations.push({
            ...emptyData,
            id: org.id,
            name: org.name
          })
        }
      })
    organizations = organizations.sort((a, b) => a.name.localeCompare(b.name))
  }
  setupOrganizations()

  const setupSalesByCategory = () => {
    salesByCategorySheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

    salesByCategorySheet.columns = [
      { header: 'Category ID', key: 'categoryId', width: 15 },
      { header: 'Category Label', key: 'categoryLabel', width: 50 },
      { header: 'Residents', key: 'residentTotalSales', width: 15 },
      { header: 'Home Order', key: 'homeOrderTotalSales', width: 15 },
      { header: 'Kiosk', key: 'kioskTotalSales', width: 15 },
      { header: 'Total', key: 'totalSales', width: 15 }
    ]
  }
  setupSalesByCategory()

  const salesByCategory = {
    resident: {},
    homeOrder: {},
    kiosk: {}
  }

  const addSaleToCategory = (type, category, amount) => {
    if (typeof salesByCategory[type][category] === 'undefined') salesByCategory[type][category] = 0
    salesByCategory[type][category] += amount
  }

  orders.forEach(order => {
    if (typeof order.statistic === 'undefined') return console.warn(`no statistic found for order ${order.id}`)

    const organization = allOrganizations.find(o => o.id === order.organizationId)
    if (!organization) return
    const organizationKey = organization.parentOrganization ? organization.parentOrganization.id : organization.id
    const organizationRef = organizations.find(o => o.id === organizationKey)
    organizationRef.orderCount.ordersTotal++
    organizationRef.netRevenue.ordersTotal += order.statistic.sum.revenue.total.net

    statistics.ordersTotal.orders++
    statistics.ordersTotal.netProductRevenue += order.statistic.sum.revenue.product.net
    statistics.ordersTotal.netDeliveryFeeRevenue += order.statistic.sum.revenue.deliveryFee.net
    statistics.ordersTotal.netRevenue += order.statistic.sum.revenue.total.net
    statistics.ordersTotal.grossRevenue += order.statistic.sum.revenue.total.gross

    let data = {
      invoiceCollectionId: order.invoiceCollectionId || '',
      organizationName: order.organizationName,
      orderDate: moment(order.createdAt.toDate()).format('DD.MM.YYYY HH:mm'),
      deliveryDate: order.deliveryDate ? moment(order.deliveryDate.toDate()).format('DD.MM.YYYY HH:mm') : ''
    }
    if (order.type === 'deliveryService') {
      const { resident, homeOrder, sum } = order.statistic
      data = {
        ...data,
        totalNetProductRevenue: sum.revenue.product.net,
        totalNetDeliveryFeeRevenue: sum.revenue.deliveryFee.net,
        totalGrossRevenue: sum.revenue.total.gross,
        totalNetRevenue: sum.revenue.total.net
      }

      organizationRef.orderCount.deliveryService++

      statistics.deliveryService.orders++
      statistics.deliveryService.netProductRevenue += sum.revenue.product.net
      statistics.deliveryService.netDeliveryFeeRevenue += sum.revenue.deliveryFee.net
      statistics.deliveryService.netRevenue += sum.revenue.total.net
      statistics.deliveryService.grossRevenue += sum.revenue.total.gross

      order.residents?.forEach(resident => {
        resident.shoppingList.items.forEach(item => {
          const product = products.find(p => p.id === item.productId)
          addSaleToCategory('resident', product.categoryId, item.amount)
        })
      })

      order.homeOrder?.shoppingList?.items.forEach(item => {
        const product = products.find(p => p.id === item.productId)
        addSaleToCategory('homeOrder', product.categoryId, item.amount)
      })

      if (resident) {
        data.residentNetProductRevenue = sum.revenue.product.net
        data.residentNetDeliveryFeeRevenue = sum.revenue.deliveryFee.net
        data.numberOfResidents = resident.numberOfResidents
        data.residentItemsCount = resident.numberOfItems
        data.residentNetRevenue = resident.revenue.total.net
        data.itemsPerResident = parseFloat((resident.numberOfItems / resident.numberOfResidents).toFixed(2))

        organizationRef.netRevenue.residents += resident.revenue.total.net
        organizationRef.orderCount.residents++

        statistics.residents.orders++
        statistics.residents.netProductRevenue += resident.revenue.product.net
        statistics.residents.netDeliveryFeeRevenue += resident.revenue.deliveryFee.net
        statistics.residents.netRevenue += resident.revenue.total.net
        statistics.residents.grossRevenue += resident.revenue.total.gross
      }

      if (homeOrder) {
        data.homeOrderNetProductRevenue = homeOrder.revenue.product.net
        data.homeOrderNetDeliveryFeeRevenue = homeOrder.revenue.deliveryFee.net
        data.homeOrderNetRevenue = homeOrder.revenue.total.net

        organizationRef.netRevenue.homeOrders += homeOrder.revenue.total.net
        organizationRef.orderCount.homeOrders++

        statistics.homeOrders.orders++
        statistics.homeOrders.netProductRevenue += homeOrder.revenue.product.net
        statistics.homeOrders.netDeliveryFeeRevenue += homeOrder.revenue.deliveryFee.net
        statistics.homeOrders.netRevenue += homeOrder.revenue.total.net
        statistics.homeOrders.grossRevenue += homeOrder.revenue.total.gross
      }

      deliveryServiceSheet.addRow(data)

      if (resident) {
        const rowNumber = deliveryServiceSheet.lastRow._number
        deliveryServiceSheet.getCell(`K${rowNumber}`).value = { formula: `G${rowNumber}/H${rowNumber}` }
      }
    } else if (order.type === 'kiosk') {
      const { kiosk } = order.statistic

      order.kioskOrder?.items.forEach(item => {
        const product = products.find(p => p.id === item.productId)
        addSaleToCategory('kiosk', product.categoryId, item.amount)
      })

      data = {
        ...data,
        totalNetProductRevenue: kiosk.revenue.product.net,
        totalNetDeliveryFeeRevenue: kiosk.revenue.deliveryFee.net,
        totalItemsCount: kiosk.numberOfItems,
        totalNetRevenue: kiosk.revenue.total.net,
        totalGrossRevenue: kiosk.revenue.total.gross
      }

      organizationRef.netRevenue.kiosk += kiosk.revenue.total.net
      organizationRef.orderCount.kiosk++

      statistics.kiosk.orders++
      statistics.kiosk.netProductRevenue += kiosk.revenue.product.net
      statistics.kiosk.netDeliveryFeeRevenue += kiosk.revenue.deliveryFee.net
      statistics.kiosk.netRevenue += kiosk.revenue.total.net
      statistics.kiosk.grossRevenue += kiosk.revenue.total.gross

      kioskSheet.addRow(data)
    } else {
      console.warn(`No statistic found for orderId ${order.id}`)
    }
  })

  organizations.forEach(organization => {
    const data = {
      organizationName: organization.name,
      totalOrderCount: organization.orderCount.ordersTotal,
      totalNetRevenue: organization.netRevenue.ordersTotal
    }
    console.log(organization.orderCount.ordersTotal)
    if (organization.orderCount.ordersTotal === 0) {
      data.icon = '☠️'
      data.notice = `No Orders Found`
    } else if (organization.orderCount.residents > 4) {
      data.icon = '⚠️'
      data.notice = `High Order Amount (${organization.orderCount.residents} resident orders)`
    }

    const statTypes = ['deliveryService', 'residents', 'homeOrders', 'kiosk', 'ordersTotal']
    statTypes.forEach(key => {
      if (organization.orderCount[key]) statistics[key].organizations++
    })

    organizationsSheet.addRow(data)

    if (organization.type !== 'parentOrganization') {
      const organizationNameCell = organizationsSheet.lastRow.getCell('organizationName')
      organizationNameCell.value = {
        text: organization.name,
        hyperlink: `https://manage.mitemma.de/organizations/${organization.id}`,
        tooltip: `Open ${organization.name} in Manage`
      }
      organizationNameCell.font = {
        color: { argb: '007BFF' },
        underline: true
      }
    }
  })

  const finishOrganizations = () => {
    organizationsSheet.getColumn('icon').alignment = { horizontal: 'center' }
    organizationsSheet.getColumn('totalNetRevenue').numFmt = '#,##0.00 €'
  }
  finishOrganizations()

  const finishDeliveryService = () => {
    const rowLenght = deliveryServiceSheet._rows.length
    ;[
      'totalGrossRevenue',
      'totalNetRevenue',
      'residentNetProductRevenue',
      'residentNetDeliveryFeeRevenue',
      'residentNetRevenue',
      'homeOrderNetProductRevenue',
      'homeOrderNetDeliveryFeeRevenue',
      'homeOrderNetRevenue',
      'totalNetProductRevenue',
      'totalNetDeliveryFeeRevenue',
      'numberOfResidents',
      'residentItemsCount',
      'itemsPerResident',
      'averageCartValue'
    ].forEach(key => {
      const cell = deliveryServiceSheet.getRow(3).getCell(key)
      const columnChar = cell._address.substr(0, 1)
      cell.value = {
        formula: ['itemsPerResident', 'averageCartValue'].includes(key)
          ? `AVERAGE(${columnChar}4:${columnChar}${rowLenght})`
          : `SUM(${columnChar}4:${columnChar}${rowLenght})`
      }
    })
    ;[
      'totalGrossRevenue',
      'totalNetRevenue',
      'residentNetProductRevenue',
      'residentNetDeliveryFeeRevenue',
      'residentNetRevenue',
      'homeOrderNetProductRevenue',
      'homeOrderNetDeliveryFeeRevenue',
      'homeOrderNetRevenue',
      'totalNetProductRevenue',
      'totalNetDeliveryFeeRevenue',
      'averageCartValue'
    ].forEach(key => {
      deliveryServiceSheet.getColumn(key).numFmt = '#,##0.00 €'
    })
    ;['itemsPerResident'].forEach(key => {
      deliveryServiceSheet.getColumn(key).numFmt = '0.0'
    })
  }
  finishDeliveryService()

  const finishKiosk = () => {
    const rowLenght = kioskSheet._rows.length
    ;[
      'totalNetProductRevenue',
      'totalNetDeliveryFeeRevenue',
      'totalItemsCount',
      'totalNetRevenue',
      'totalGrossRevenue'
    ].forEach(key => {
      const cell = kioskSheet.getRow(3).getCell(key)
      const columnChar = cell._address.substr(0, 1)
      cell.value = {
        formula: `SUM(${columnChar}4:${columnChar}${rowLenght})`
      }
    })
    ;['totalNetProductRevenue', 'totalNetDeliveryFeeRevenue', 'totalNetRevenue', 'totalGrossRevenue'].forEach(key => {
      kioskSheet.getColumn(key).numFmt = '#,##0.00 €'
    })
    ;['totalItemsCount'].forEach(key => {
      kioskSheet.getColumn(key).numFmt = '0'
    })
  }
  finishKiosk()

  const finishSalesByCategory = () => {
    const usedCategories = []
    Object.keys(salesByCategory).forEach(type => {
      Object.keys(salesByCategory[type]).forEach(categoryId => {
        if (!usedCategories.includes(categoryId)) usedCategories.push(categoryId)
      })
    })
    usedCategories.sort(function(a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })
    usedCategories.forEach(categoryId => {
      const categoryLabel =
        categoryId.length === 1
          ? catalog.categories[categoryId].label
          : categoryId.length === 2
          ? catalog.categories[categoryId.substring(0, 1)].categories[categoryId].label
          : catalog.categories[categoryId.substring(0, 1)].categories[categoryId.substring(0, 2)].categories[categoryId]
              .label

      const residentTotalSales = salesByCategory.resident[categoryId] || 0
      const homeOrderTotalSales = salesByCategory.homeOrder[categoryId] || 0
      const kioskTotalSales = salesByCategory.kiosk[categoryId] || 0
      salesByCategorySheet.addRow({
        categoryId,
        categoryLabel,
        residentTotalSales,
        homeOrderTotalSales,
        kioskTotalSales,
        totalSales: residentTotalSales + homeOrderTotalSales + kioskTotalSales
      })
    })
  }
  finishSalesByCategory()

  const finishStatistics = () => {
    const labels = {
      deliveryService: 'Delivery Service',
      residents: '⌞ Residents',
      homeOrders: '⌞ Home Orders',
      kiosk: 'Kiosk',
      ordersTotal: 'Orders Total',
      equipment: 'Equipment',
      total: 'Total'
    }

    const getRowNumber = statisticsKey => statisticRows.indexOf(statisticsKey) + 2

    Object.keys(statistics).forEach(statsKey => {
      const { orders, organizations, netProductRevenue, netDeliveryFeeRevenue, netRevenue, grossRevenue } = statistics[
        statsKey
      ]
      const data = {
        position: labels[statsKey],
        orders,
        organizations,
        netProductRevenue,
        netDeliveryFeeRevenue,
        netRevenue,
        grossRevenue
      }

      statisticsSheet.addRow(data)

      const rowNumber = statisticsSheet.lastRow._number
      statisticsSheet.getCell(`H${rowNumber}`).value = { formula: `B${rowNumber}/C${rowNumber}` }
      statisticsSheet.getCell(`I${rowNumber}`).value = { formula: `F${rowNumber}/C${rowNumber}` }
      statisticsSheet.getCell(`J${rowNumber}`).value = { formula: `F${rowNumber}/B${rowNumber}` }
      statisticsSheet.getCell(`K${rowNumber}`).value = { formula: `G${rowNumber}/C${rowNumber}` }
      statisticsSheet.getCell(`L${rowNumber}`).value = { formula: `G${rowNumber}/B${rowNumber}` }
      statisticsSheet.getCell(`H${rowNumber}`).value = { formula: `B${rowNumber}/C${rowNumber}` }
    })

    const columnKeys = [
      'netProductRevenue',
      'netDeliveryFeeRevenue',
      'netRevenue',
      'grossRevenue',
      'netRevenuePerOrganizaiton',
      'netRevenuePerOrder',
      'grossRevenuePerOrganizaiton',
      'grossRevenuePerOrder'
    ]
    columnKeys.forEach(key => {
      statisticsSheet.getColumn(key).numFmt = '#,##0.00 €'
    })
    statisticsSheet.getColumn('ordersPerOrganization').numFmt = '0.0'

    statisticsSheet.addRow({ position: labels.total })

    statisticsSheet.getCell(`F${getRowNumber('total')}`).value = {
      formula: `F${getRowNumber('ordersTotal')}+F${getRowNumber('equipment')}`
    }
    statisticsSheet.getCell(`G${getRowNumber('total')}`).value = {
      formula: `G${getRowNumber('ordersTotal')}+G${getRowNumber('equipment')}`
    }

    const highLightRowsKeys = [getRowNumber('ordersTotal'), getRowNumber('total')]
    highLightRowsKeys.forEach(rowKey => {
      const row = statisticsSheet.getRow(rowKey)
      row.font = {
        bold: true
      }
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFCCCCCC'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }
    })
  }
  finishStatistics()

  workbook.xlsx.writeBuffer().then(function(data) {
    var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, `Orders ${from.format('YYYY-MM-DD')} - ${to.format('YYYY-MM-DD')}.xlsx`)
  })
}

export default GenerateOrdersReport
